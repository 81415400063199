import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import { staticStyles } from '@/styles/variables/sizes';

/**
 * Represents the PageNotFound component.
 * @function PageNotFound
 * @returns {JSX.Element} JSX element representing the 404 error page.
 */
const PageNotFound = () => {
  const {
    t
  } = useTranslation(['common']);
  return <main data-sentry-component="PageNotFound" data-sentry-source-file="PageNotFound.tsx">
      <div className={`${staticStyles.max_w_1440} mx-auto my-0 overflow-hidden`}>
        <section className={`${staticStyles.min_h_80vh} mt-20 flex flex-col items-center justify-center text-center `}>
          <h1 data-testid="page-not-exist" className={`${staticStyles.t_85} ${staticStyles.leading_140}`}>
            {t('not_found.page_not_exist')}
          </h1>
          <Link href="/login" className={`${staticStyles.t_30} flex items-center`} data-sentry-element="Link" data-sentry-source-file="PageNotFound.tsx">
            {t('not_found.take_home')}
            <BiRightArrowAlt data-sentry-element="BiRightArrowAlt" data-sentry-source-file="PageNotFound.tsx" />
          </Link>
        </section>
      </div>
    </main>;
};
export default PageNotFound;