import type { GetStaticProps } from 'next';
import router from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { useEffect } from 'react';
import PageNotFound from '@/components/authComponents/PageNotFound';
import dynamicRoute from '@/miscellaneous/constant';

/**
 * PageNotFound component.
 * This component is used to display a "Page Not Found" error page.
 * @returns {JSX.Element} The rendered JSX element.
 */
const PageNotFoundPage = () => {
  useEffect(() => {
    setTimeout(() => {
      router.push(dynamicRoute.login, dynamicRoute.login, {
        locale: router.locale
      });
    }, 3000);
  }, []);
  return <PageNotFound data-sentry-element="PageNotFound" data-sentry-component="PageNotFoundPage" data-sentry-source-file="404.tsx" />;
};
// add a 3 second timer that redirects to /login

//

export const getStaticProps: GetStaticProps = async ({
  locale
}) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en'))
    }
  };
};
export default PageNotFoundPage;